export const FIRST_STEP_OPTIONS = [
	{ value: 'customers', label: 'Customers (owners/buyers)' },
	{ value: 'sales', label: 'Sales Teams (internal/external)' },
];
export const YES_NO_OPTIONS = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];

export const CHOOSE_INDUSTRY = [
	{ value: '', label: 'Choose Industry', isDisabled: true },
	{ value: 'Aerospace & Defense', label: 'Aerospace & Defense' },
	{ value: 'Agriculture', label: 'Agriculture' },
	{ value: 'Apparel', label: 'Apparel' },
	{ value: 'Automotive', label: 'Automotive' },
	{ value: 'Auto Parts', label: 'Auto Parts' },
	{ value: 'Beauty Supply', label: 'Beauty Supply' },
	{ value: 'Building Supplies', label: 'Building Supplies' },
	{ value: 'Business Services', label: 'Business Services' },
	{ value: 'Chemicals', label: 'Chemicals' },
	{ value: 'Computers', label: 'Computers' },
	{ value: 'Electrical', label: 'Electrical' },
	{ value: 'Electronics', label: 'Electronics' },
	{ value: 'Energy/Utilities', label: 'Energy/Utilities' },
	{ value: 'Financial', label: 'Financial' },
	{ value: 'Food & Beverage', label: 'Food & Beverage' },
	{ value: 'Food Equipment', label: 'Food Equipment ' },
	{ value: 'Food Service', label: 'Food Service' },
	{ value: 'Hardware', label: 'Hardware' },
	{ value: 'Healthcare', label: 'Healthcare' },
	{ value: 'Heavy Equipment', label: 'Heavy Equipment' },
	{ value: 'Hospitality', label: 'Hospitality' },
	{ value: 'HVAC', label: 'HVAC' },
	{ value: 'Industrial Equipment/Supplies', label: 'Industrial Equipment/Supplies' },
	{ value: 'Insurance', label: 'Insurance' },
	{ value: 'Janitorial and Sanitation', label: 'Janitorial and Sanitation' },
	{ value: 'Landscaping', label: 'Landscaping' },
	{ value: 'Machinery', label: 'Machinery' },
	{ value: 'Medical/Dental & Healthcare Equipment', label: 'Medical/Dental & Healthcare Equipment' },
	{ value: 'Mining', label: 'Mining' },
	{ value: 'Packaging', label: 'Packaging' },
	{ value: 'Pet Supplies', label: 'Pet Supplies' },
	{ value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
	{ value: 'Plastics ', label: 'Plastics ' },
	{ value: 'Plumbing/HVAC', label: 'Plumbing/HVAC' },
	{ value: 'Pool & Spa', label: 'Pool & Spa' },
	{ value: 'Real Estate', label: 'Real Estate' },
	{ value: 'Retail', label: 'Retail' },
	{ value: 'Safety Equipment', label: 'Safety Equipment' },
	{ value: 'Security', label: 'Security' },
	{ value: 'Telecommunications', label: 'Telecommunications' },
	{ value: 'Tires/Wheels', label: 'Tires/Wheels' },
	{ value: 'Transportation', label: 'Transportation' },
	{ value: 'Other', label: 'Other' },
	{ value: 'Don’t Know', label: 'Don’t Know' },
];
export const CHOOSE_PRIMARY_GOAL = [
	{ value: '', label: 'Choose your primary goal', isDisabled: true },
	{ value: 'Increase Sales', label: 'Increase Sales' },
	{ value: 'Increase channel sales revenue', label: 'Increase channel sales revenue' },
	{ value: 'Improve customer loyalty', label: 'Improve customer loyalty' },
];

export const INDUSTRY_VERTICAL = [
	{ value: '', label: 'Choose Merchant Type', isDisabled: true },
	{ value: 'Distributor', label: 'Distributor' },
	{ value: 'Manufacturer', label: 'Manufacturer' },
	{ value: 'Service Provider', label: 'Service Provider' },
	{ value: 'Value-Added Reseller', label: 'Value-Added Reseller' },
	{ value: 'Retailer', label: 'Retailer' },
]