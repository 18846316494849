import {useContext} from 'react';
import {ICalcData, ICustomerResult, ICustomerRewardsProgram, ISalesResult} from '../types';
import AppContext from '../context/AppContext';
import {OTHER} from 'content/constants';

export const useCalculator = (): { onResult: (form: ICalcData) => void } => {
    const {changeResult} = useContext(AppContext);

    const onResult = (form: ICalcData) => {
        changeResult({
            salesIncentiveProgram: calculateSalesIncentive(form),
            customerRewardsProgram: calculateCustomerRewards(form.customerRewardsProgram),
        });
    };

    const calculateSalesIncentive = ({
          salesIncentiveProgram: {
          INC_TargetSize,
          INC_AvgAnnlSales,
          INC_AvgGross,
          INC_GrowthAccts,
          INC_IncPayout, INC_SalesTarget,INC_GrowthCat}}: ICalcData): ISalesResult => {

        const INC_CAL_RevIncr = +INC_TargetSize * +INC_AvgAnnlSales * ((+INC_SalesTarget + +INC_GrowthAccts + +INC_GrowthCat) / 100);
        const INC_CAL_AnnlCost = (+INC_IncPayout / 100) * (+INC_TargetSize * +INC_AvgAnnlSales + +INC_CAL_RevIncr * 2)
        const INC_CAL_AddlGross = +INC_CAL_RevIncr * (+INC_AvgGross / 100)
        const INC_CAL_IncrGP = +INC_CAL_AddlGross / +INC_CAL_AnnlCost
        const INC_CAL_ROIperc = Math.round(((+INC_CAL_AddlGross  - (+INC_CAL_AnnlCost))/ +INC_CAL_AnnlCost) * 100)
        return {INC_CAL_RevIncr, INC_CAL_AnnlCost, INC_CAL_AddlGross, INC_CAL_IncrGP, INC_CAL_ROIperc} as ISalesResult;
    }

    const calculateCustomerRewards = ({
                                          INC_ProgParticipation,
                                          INC_AvgAnnlSalesAct,
                                          INC_PrefSupplier,
                                          INC_BusExpansion,
                                          INC_InactiveCust,
                                          INC_ActiveCust,
                                          INC_AvgGross,
                                      }: ICustomerRewardsProgram): ICustomerResult => {
        const REW_Yr1ProgCost = Math.round(
            (+INC_ProgParticipation / 100) *
            +INC_AvgAnnlSalesAct *
            OTHER.INC_Sales_MemberRev *
            (OTHER.INC_Cost_MemberRev +
                (OTHER.INC_BaseIncrTier1 / 100) *
                (OTHER.INC_Sales_Tier1 * OTHER.INC_Cost_Tier1 +
                    OTHER.INC_Sales_Tier2 * OTHER.INC_Cost_Tier2 +
                    OTHER.INC_Sales_Tier3 * OTHER.INC_Cost_Tier3 +
                    OTHER.INC_Sales_Tier4 * OTHER.INC_Cost_Tier4) +
                (1 - +INC_PrefSupplier / 100) *
                (OTHER.INC_Sales_ShareWall / 100) *
                OTHER.INC_Cost_ShareWall +
                (INC_BusExpansion === 'yes' ? OTHER.INC_Sales_InactRecap / 100 : 0) *
                OTHER.INC_Cost_CatIncrease) +
            +INC_InactiveCust *
            (OTHER.INC_Sales_InactRecap / 100) *
            ((INC_AvgAnnlSalesAct / INC_ActiveCust) * OTHER.INC_Cost_InactRecap1 +
                OTHER.INC_Cost_InactRecap2)
        );

        const REW_Yr1RevIncr = Math.round(
            (+INC_ProgParticipation / 100) *
            +INC_AvgAnnlSalesAct *
            OTHER.INC_Sales_MemberRev *
            ((INC_BusExpansion === 'yes' ? OTHER.INC_Sales_InactRecap / 100 : 0) +
                (OTHER.INC_BaseIncrTier1 / 100) *
                (OTHER.INC_Sales_Tier1 +
                    OTHER.INC_Sales_Tier2 +
                    OTHER.INC_Sales_Tier3 +
                    OTHER.INC_Sales_Tier4) +
                (1 - +INC_PrefSupplier / 100) * (OTHER.INC_Sales_ShareWall / 100)) +
            1 +
            (+INC_InactiveCust * (OTHER.INC_Sales_InactRecap / 100) * +INC_AvgAnnlSalesAct) /
            +INC_ActiveCust
        );

        const REW_Yr1IncrGP = Math.round(REW_Yr1RevIncr * (+INC_AvgGross / 100));
        const REW_IncrGPPerDollar = +(+REW_Yr1IncrGP / +REW_Yr1ProgCost).toFixed(2);
        const REW_ROINetIncrGP = REW_Yr1IncrGP - +REW_Yr1ProgCost;
        const REW_Yr1ProgCostPerc = +(
            (+REW_Yr1ProgCost /
                ((+INC_ProgParticipation / 100) * +INC_AvgAnnlSalesAct * OTHER.INC_Sales_MemberRev +
                    +REW_Yr1RevIncr)) *
            100
        ).toFixed(1);

        const REW_ROIPerc = Math.round((REW_ROINetIncrGP / +REW_Yr1ProgCost) * 100);
        return {
            REW_ROINetIncrGP,
            REW_IncrGPPerDollar,
            REW_Yr1IncrGP,
            REW_Yr1RevIncr,
            REW_Yr1ProgCost,
            REW_Yr1ProgCostPerc,
            REW_ROIPerc,
        } as ICustomerResult;
    };

    return {onResult};
};
