import AppContext from 'context/AppContext';
import React, {Fragment, useContext} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {ICalcData, ISalesIncentiveProgram, UAudience, ICustomerRewardsProgram, IStep} from 'types';
import {EStep} from 'content/enum';
import {LABEL_FIRST_STEP} from 'content/constants/defaultConstants';
import StepRadio from './StepRadio';
import Step from './Step';
import {STEPS} from 'content/constants/stepsConstant';
import ResultStep from "./ResultStep";

const Calculator = (): JSX.Element => {
    const methods = useForm({
        defaultValues: {
            audienceType: '' as UAudience,
            salesIncentiveProgram: {} as ISalesIncentiveProgram,
            customerRewardsProgram: {} as ICustomerRewardsProgram,
        } as ICalcData,
        shouldUnregister: false,
    });
    const {stepType, changeStep, changeProgram} = useContext(AppContext);
    const makeFirstStep = () => {
        const {audienceType} = methods.getValues();
        changeProgram(audienceType)

        changeStep(
            audienceType === LABEL_FIRST_STEP.CUSTOMERS ? EStep.QUESTION_2B : EStep.QUESTION_2A
        );
    };

    const renderStep = (type: string) => {
        if (type === EStep.RESULT) {

            return (
                <ResultStep/>
            );
        } else {
            return STEPS.map((step: IStep) => (
                <Fragment key={step.stepType}>
                    {step.stepType === type &&
                    (step.inputType === 'number' ? (
                        <Step
                            title={step.title}
                            question={step.question}
                            description={step.description}
                            questionTitle={step.questionTitle}
                            inputName={step.inputName}
                            onNextStep={step.nextStep ? () => changeStep(step.nextStep) : undefined}
                            onBackStep={() => changeStep(step.backStep)}
                            rules={step.rules}
                            maskOpt={step.maskOpt}
                            typeNextStep={step.nextStep}
                            placeholder={step.placeholder}
                        />
                    ) : (
                        <StepRadio
                            options={step.options ? step.options : []}
                            title={step.title}
                            question={step.question}
                            description={step.description}
                            questionTitle={step.questionTitle}
                            inputName={step.inputName}
                            onNextStep={() =>
                                step.stepType === EStep.FIRST_STEP
                                    ? makeFirstStep()
                                    : changeStep(step.nextStep)
                            }
                            onBackStep={step.backStep ? () => changeStep(step.backStep) : undefined}
                            rules={step.rules}
                            titleDescription={step.titleDescription || ''}
                        />
                    ))}
                </Fragment>
            ));
        }
    };
    return <FormProvider {...methods}>{renderStep(stepType)}</FormProvider>;
};

export default Calculator;
