import { useEffect } from 'react';

const useMoveLogoToBottom = (): void => {
	useEffect(() => {
		document.getElementById('1541480276')?.classList.add('flex-column-reverse');
		console.log('useMoveLogoToBottom');
		return () => {
			document.getElementById('1541480276')?.classList.remove('flex-column-reverse');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useMoveLogoToBottom;
