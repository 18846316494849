import React, {FC} from 'react';
import {Bar, BarChart, CartesianGrid, LabelList, LabelProps, Legend, LegendProps, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {IBarChartData} from "types/interfaces/IBarChartData";
import {convertToShortNumber} from "../utils/currencyFormatter";

interface Props {
    data: IBarChartData[];
    onBinaryImage: (value: string) => void;
    programCost: number;
    revenueIncrease: number;
    grossMargin: number;
}

const TinyBarChart: FC<Props> = ({data, programCost, revenueIncrease, grossMargin}: Props): JSX.Element => {
    const labelYaxis = (value: string) => convertToShortNumber(+(value || 0));

    const renderCustomizedLabel = (props: LabelProps, increase: number) => {
        const {x, y, width} = props && props;
        const radius = 10;

        const label = (+(increase || 0) / programCost).toFixed(1) + 'X';
        return (
            <g>
                <text
                    x={Number(x) + Number(width) / 2}
                    y={Number(y) - radius}
                    fill="#000"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontFamily="NotoSans"
                    fontSize="14px"
                >
                    {label}
                </text>
            </g>
        );
    };

    // const renderCustomizedLabelBottom = (props: LabelProps, label: string) => {
    //     const {x, y, width, value, height} = props && props;
    //     return (
    //         <g>
    //             <text
    //                 x={Number(x) + Number(width) / 2}
    //                 y={Number(y) + Number(height) + 15}
    //                 fill="#000"
    //                 textAnchor="middle"
    //                 fontSize="16px"
    //                 fontFamily="NotoSans"
    //                 dominantBaseline="middle">
    //                 {label}
    //             </text>
    //             <text
    //                 x={Number(x) + Number(width) / 2}
    //                 y={Number(y) + Number(height) + 35}
    //                 fill="#000"
    //                 textAnchor="middle"
    //                 fontSize="14px"
    //                 fontFamily="NotoSans"
    //                 dominantBaseline="middle">
    //                 {currencyFormatter(Number(value) + programCost)}
    //             </text>
    //         </g>
    //     );
    // };

    const caseValue = (value: string): string => {
        switch (value) {
            case 'uv':
                return 'Incremental Gross Margin'
            case 'pv':
                return 'Revenue Increase'
            case 'pc':
                return 'Program Investment'
            default:
                return ''
        }
    }

    const renderLi = (color: string) => {
        return (
            <span className="legend-span" style={{backgroundColor: color}}></span>)
    }

    const renderLegend = (props: LegendProps) => {
        const {payload} = props && props;
        let count = 0;
        return (
            <ul className="recharts-default-legend d-flex flex-direction-column" >
                {
                    payload && payload.map(({color, value}, index: number) => {
                            if (value === 'pc' && count) return null
                            if (value === 'pc') count++
                            return (
                                <li key={`item-${index}`} className="legend-li">{renderLi(color ? color : '')}{caseValue(value)}</li>
                            )
                        }
                    )
                }
            </ul>
        );
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{top: 20, right: 0, left: -43, bottom: 55}} id="canvas">
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis tickFormatter={(value) => labelYaxis(value)} width={80} fontSize="12px" fontFamily="NotoSans"/>
                <Bar dataKey="pc" fill="#ccc" stackId="pv" maxBarSize={140}>
                    {/*<LabelList dataKey="pv" content={(props) => renderCustomizedLabelBottom(props, 'Revenue Increase')}*/}
                    {/*           position="insideBottom"/>*/}
                </Bar>
                <Bar dataKey="pv" fill="#CB1A27" stackId="pv" maxBarSize={140}>
                    <LabelList dataKey="pv" content={props => renderCustomizedLabel(props, revenueIncrease)} position="top"/>
                </Bar>

                <Bar dataKey="pc" fill="#ccc" stackId="uv" maxBarSize={140}>
                    {/*<LabelList dataKey="uv" content={(props) => renderCustomizedLabelBottom(props, 'Incremental Gross Margin')}*/}
                    {/*           position="insideBottom"/>*/}
                </Bar>
                <Bar dataKey="uv" fill="#7F7F7F" stackId="uv" maxBarSize={140}>
                    <LabelList dataKey="uv" content={props => renderCustomizedLabel(props, grossMargin)} position="top"/>
                </Bar>

                <Legend
                    content={(props) => renderLegend(props as LegendProps)}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default TinyBarChart;
