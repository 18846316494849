import React, { FC } from 'react';
type Props = {
	text: string;
	className?: string;
	type?: 'page' | 'section';
};
const Title: FC<Props> = ({ text, className = '', type = 'page' }: Props) => {
	return type === 'page' ? (
		<h2 className={`title ${className}`}>{text}</h2>
	) : (
		<h4 className={`title title--section ${className}`}>{text}</h4>
	);
};

export default Title;
