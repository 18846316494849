import useMoveLogoToBottom from 'hooks/useMoveLogoToBottom';
import React, { FC, useEffect } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { ISelectOpt } from 'types';
import { isObjEmpty, splitKeys } from 'utils';
import CustomRadio from './fields/CustomRadio';
import Layout from './Layout';
import Title from './text/Title';

type Props = {
	title: string;
	question: string;
	description: string;
	inputName: string;
	options: ISelectOpt[];
	onNextStep: () => void;
	onBackStep?: () => void;
	questionTitle: string;
	rules: RegisterOptions;
	titleDescription: string;
};
const StepRadio: FC<Props> = ({
	title,
	question,
	description,
	inputName,
	options,
	onNextStep,
	onBackStep,
	questionTitle,
	rules,
	titleDescription = '',
}: Props): JSX.Element => {
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useFormContext();
	useMoveLogoToBottom();
	const [firstKey, secondKey] = splitKeys(inputName);
	useEffect(() => {
		if (!onBackStep) {
			document.getElementById('1541480276')?.classList.add('flex-column-reverse');
		}
		return () => {
			document.getElementById('1541480276')?.classList.remove('flex-column-reverse');
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Layout
			btnClass={''}
			clickNext={onNextStep ? handleSubmit(onNextStep) : undefined}
			clickBack={onBackStep}
			nextBtnText='Next'
			title={title}>
			<div className='step'>
				<div>
					<Title text={titleDescription} className={'title--subtitle title--mobile-sm'} type='section' />
				</div>
				<div>
					<Title text={questionTitle} type='section' />
				</div>
				<div>
					<p className='step__description text-left'>{question}</p>
				</div>
				<div>
					<Controller
						control={control}
						name={inputName}
						rules={rules}
						render={({ field: { onChange, value } }) => (
							<CustomRadio
								options={options}
								value={options.find((v) => v.value === value) || ({} as ISelectOpt)}
								onChange={(opt: ISelectOpt) => onChange(opt.value)}
							/>
						)}
					/>
					{!isObjEmpty(errors) && (
						<p className='step__error'>
							{(secondKey
								? errors[firstKey][secondKey]?.message
								: errors[firstKey]?.message) || ''}
						</p>
					)}
				</div>
				<div>
					<div dangerouslySetInnerHTML={{ __html: description }}></div>
				</div>
			</div>
		</Layout>
	);
};

export default StepRadio;
