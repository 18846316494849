import React, {FC, useContext, useEffect, useState} from 'react';
import Title from './text/Title';
import {Controller, useForm} from 'react-hook-form';
import CustomInput from './fields/CustomInput';
import {IMaskOpt} from '../types';
import {toggleInvalidInput} from '../utils';
import {EMAIL_PATTERN} from '../content/constants/validationPatterns';
import {useFetchAPI} from '../hooks/useFetchAPI';
import AppContext from '../context/AppContext';

interface Props {
    onClose: () => void;
    base64Chart: string;
}

type FormValues = {
    recipientName: string;
    recipientEmail: string;
    name: string;
    email: string;
    message?: string;
};

const ShareModal: FC<Props> = ({onClose, base64Chart}: Props): JSX.Element => {
    const {control, handleSubmit} = useForm<FormValues>({
        defaultValues: {
            recipientName: '',
            recipientEmail: '',
            name: '',
            email: '',
            message: '',
        },
    });
    const {requestAPI} = useFetchAPI();
    const [checkRule, setCheckRule] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const {
        resultData: {salesIncentiveProgram, customerRewardsProgram},
        inputData,
        typeProgram,
    } = useContext(AppContext);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    const onSubmitForm = async (data: FormValues) => {
        await requestAPI(
            'POST',
            'https://sheet.best/api/sheets/514c82b8-bc3f-4e9f-bcce-dc2d7b721902',
            JSON.stringify({
                'Recipient Name': data.recipientName,
                'Recipient Email Address': data.recipientEmail,
                'Your Name': data.name,
                'Your Email Address': data.email,
                Message: data.message,
                Date: new Date().toLocaleDateString(),
            })
        );
        typeProgram === 'sales' ? await sendRsultSales(data) : await sendRsultCustomer(data);
        setShowForm(false);
    };

    const sendRsultCustomer = async (data: FormValues) => {
        await requestAPI(
            'POST',
            `${process.env.REACT_APP_API_URL}/api/v1/email/share-result-customer`,
            JSON.stringify({
                customerInfoDto: {
                    inputs: {
                        targetAudience: 'Customers',
                        activeCustomers: Number(inputData?.customerRewardsProgram.INC_ActiveCust),
                        programParticipation: Number(
                            inputData.customerRewardsProgram.INC_ProgParticipation
                        ),
                        annualSales: Number(inputData.customerRewardsProgram.INC_AvgAnnlSalesAct),
                        lapsedCustomers: Number(inputData.customerRewardsProgram.INC_InactiveCust),
                        businessExpansion: inputData.customerRewardsProgram.INC_BusExpansion,
                        preferredSupplier: Number(
                            inputData.customerRewardsProgram.INC_PrefSupplier
                        ),
                        grossMargin: Number(inputData.customerRewardsProgram.INC_AvgGross),
                    },
                    result: {
                        roiPerc: customerRewardsProgram.REW_ROIPerc,
                        yr1ProgCost: customerRewardsProgram.REW_Yr1ProgCost,
                        yr1ProgCostPerc: customerRewardsProgram.REW_Yr1ProgCostPerc,
                        yr1RevIncr: customerRewardsProgram.REW_Yr1RevIncr,
                        yr1IncrGP: customerRewardsProgram.REW_Yr1IncrGP,
                        incrGPPerDollar: customerRewardsProgram.REW_IncrGPPerDollar,
                        roiNetIncrGP: customerRewardsProgram.REW_ROINetIncrGP,
                        chartBase64: base64Chart,
                    },
                },
                shareResultDto: {
                    recipientName: data.recipientName,
                    recipientEmail: data.recipientEmail,
                    userName: data.name,
                    userEmail: data.email,
                },
            })
        );
    };

    const sendRsultSales = async (data: FormValues) => {
        await requestAPI(
            'POST',
            `${process.env.REACT_APP_API_URL}/api/v1/email/share-result-sales`,
            JSON.stringify({
                salesInfoDto: {
                    inputs: {
                        targetAudience: 'Sales Teams',
                        salesTeamMembers: Number(inputData.salesIncentiveProgram.INC_TargetSize),
                        annualSalesPerMember: Number(
                            inputData?.salesIncentiveProgram.INC_AvgAnnlSales
                        ),
                        salesIncreaseTarget: Number(
                            inputData?.salesIncentiveProgram.INC_SalesTarget
                        ),
                        desiredGrowthFromNewAccounts: Number(
                            inputData?.salesIncentiveProgram.INC_GrowthAccts
                        ),
                        desiredGrowthFromCategoryExpansion: Number(
                            inputData?.salesIncentiveProgram.INC_GrowthCat
                        ),
                        grossMargin: Number(inputData?.salesIncentiveProgram.INC_AvgGross),
                        salesBudgeted: Number(inputData?.salesIncentiveProgram.INC_IncPayout),
                    },
                    result: {
                        roiPerc: salesIncentiveProgram.INC_CAL_ROIperc,
                        annlCost: salesIncentiveProgram.INC_CAL_AnnlCost,
                        revIncr: salesIncentiveProgram.INC_CAL_RevIncr,
                        addlGross: salesIncentiveProgram.INC_CAL_AddlGross,
                        increGP: salesIncentiveProgram.INC_CAL_IncrGP,
                        chartBase64: base64Chart,
                    },
                },
                shareResultDto: {
                    recipientName: data.recipientName,
                    recipientEmail: data.recipientEmail,
                    userName: data.name,
                    userEmail: data.email,
                },
            })
        );
    };

    return (
        <div className='popup'>
            <div className='popup__inner'>
                <div className='d-flex justify-content-end' onClick={onClose}>
                    <span className='close'>x</span>
                </div>
                {showForm ? (
                    <>
                        <Title text={'Share Your Result!'}/>
                        <p>
                            Fill in the form below to create a link to share your ROI Calculator
                            results with your colleagues.
                        </p>
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                            <Controller
                                control={control}
                                name='recipientName'
                                rules={{required: true}}
                                render={({field: {onChange, value}, fieldState}) => (
                                    <CustomInput
                                        maskOpt={{} as IMaskOpt}
                                        inputName='recipientName'
                                        label=''
                                        value={value}
                                        type='text'
                                        placeholder='Recipient Name *'
                                        className={`${toggleInvalidInput(fieldState.invalid)}`}
                                        onChange={(event) => onChange(event)}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name='recipientEmail'
                                rules={{required: true, pattern: EMAIL_PATTERN}}
                                render={({field: {onChange, value}, fieldState}) => (
                                    <CustomInput
                                        maskOpt={{} as IMaskOpt}
                                        inputName='recipientEmail'
                                        label=''
                                        value={value}
                                        onChange={(event) => onChange(event)}
                                        className={`${toggleInvalidInput(fieldState.invalid)}`}
                                        type='text'
                                        placeholder='Recipient Email Address *'
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name='name'
                                rules={{required: true}}
                                render={({field: {onChange, value}, fieldState}) => (
                                    <CustomInput
                                        maskOpt={{} as IMaskOpt}
                                        inputName='name'
                                        label=''
                                        value={value}
                                        type='text'
                                        placeholder='Your Name *'
                                        onChange={(event) => onChange(event)}
                                        className={`${toggleInvalidInput(fieldState.invalid)}`}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='email'
                                rules={{required: true, pattern: EMAIL_PATTERN}}
                                render={({field: {onChange, value}, fieldState}) => (
                                    <CustomInput
                                        maskOpt={{} as IMaskOpt}
                                        inputName='email'
                                        label=''
                                        value={value}
                                        onChange={(event) => onChange(event)}
                                        className={`${toggleInvalidInput(fieldState.invalid)}`}
                                        type='text'
                                        placeholder='Your Email Address *'
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='message'
                                render={({field: {onChange, value}, fieldState}) => (
                                    <textarea
                                        value={value}
                                        onChange={(event) => onChange(event)}
                                        className={`${toggleInvalidInput(fieldState.invalid)} mt-2`}
                                        placeholder='Add a message to the link your result'
                                    />
                                )}
                            />

                            <div className='d-flex align-items-baseline'>
                                <input
                                    type='checkbox'
                                    checked={checkRule}
                                    onChange={() => setCheckRule(!checkRule)}
                                />
                                <div onClick={() => setCheckRule(!checkRule)}>
                                    <span className='pl-1 text-left ml-1'>
									{' '}
                                        We are committed to your privacy. Lift & Shift uses the
									information you provide here to contact you about our products
									and services. You may unsubscribe from these communications at
									any time. For more information, see our{' '}
                                    <a
                                       className='link'
                                       href='https://www.lift-and-shift.com/privacy-policy'>
                                       Privacy Policy.
									</a></span>
                                </div>
                            </div>

                            <button
                                type='submit'
                                className='btn btn__line w-100 mt-2'
                                disabled={!checkRule}>
                                SHARE MY ROI RESULTS NOW
                            </button>
                        </form>
                    </>
                ) : (
                    <>
                        <Title text={'Thank You'}/>
                        <p className='text-center'>
                            A link has been sent to the address you provided.{' '}
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};
export default ShareModal;
