import React, { FC, useContext, useState } from 'react';
import CustomInput from './fields/CustomInput';
import CustomSelect from './fields/CustomSelect';
import {
	CHOOSE_INDUSTRY,
	CHOOSE_PRIMARY_GOAL,
	INDUSTRY_VERTICAL,
} from '../content/constants/selectOptions';
import { IMaskOpt, ISelectOpt } from '../types';
import { Controller, useForm } from 'react-hook-form';
import { toggleInvalidInput } from '../utils';
import { PhoneMask } from 'utils/maskGenerator';
import { EMAIL_PATTERN } from 'content/constants/validationPatterns';
import Title from './text/Title';
import { useFetchAPI } from '../hooks/useFetchAPI';
import AppContext from '../context/AppContext';
import { SVGDownload } from 'assets/SVGIcons';

type FormValues = {
	firstName: string;
	lastName: string;
	companyName: string;
	email: string;
	phone: string;
	industry: string;
	primaryGoal: string;
	industryVertical: string;
	contactMe: boolean;
};

interface Props {
	base64Chart: string;
	generateUrl: () => void;
	convertChartToPng: () => void;
}

const ContactUsForm: FC<Props> = ({
	base64Chart,
	generateUrl,
	convertChartToPng,
}: Props): JSX.Element => {
	const [showForm, setShowForm] = useState<boolean>(true);
	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			firstName: '',
			lastName: '',
			companyName: '',
			email: '',
			phone: '',
			industry: '',
			primaryGoal: '',
			industryVertical: '',
			contactMe: false,
		},
	});
	const { requestAPI, requestAPIBlob } = useFetchAPI();
	const {
		resultData: { salesIncentiveProgram, customerRewardsProgram },
		inputData,
		typeProgram,
	} = useContext(AppContext);

	const onSubmitForm = async (data: FormValues) => {
		await requestAPI(
			'POST',
			'https://sheet.best/api/sheets/f19f31af-0782-4884-b894-add50beb5865',
			JSON.stringify({
				'First Name': data.firstName,
				'Last Name': data.lastName,
				'Company Name': data.companyName,
				Email: data.email,
				Phone: data.phone,
				Industry: data.industry,
				'Primary Goal': data.primaryGoal,
				'Industry Vertical': data.industryVertical,
				Date: new Date().toLocaleDateString(),
			})
		);
		await requestAPI(
			'POST',
			`${process.env.REACT_APP_API_URL}/api/v1/email/contact-us`,
			JSON.stringify({
				firstName: data.firstName,
				lastName: data.lastName,
				companyName: data.companyName,
				workEmail: data.email,
				phoneNumber: data.phone,
				industry: data.industry,
				industryVertical: data.industryVertical,
				primaryGoal: data.primaryGoal,
				contactMe: data.contactMe,
			})
		);
		convertChartToPng();
		setShowForm(false);
	};

	const getDocumentCustomer = async () => {
		const file = await requestAPIBlob(
			'POST',
			`${process.env.REACT_APP_API_URL}/api/v1/document/customer`,
			JSON.stringify({
				inputs: {
					targetAudience: 'Customers',
					activeCustomers: Number(inputData?.customerRewardsProgram.INC_ActiveCust),
					programParticipation: Number(
						inputData.customerRewardsProgram.INC_ProgParticipation
					),
					annualSales: Number(inputData.customerRewardsProgram.INC_AvgAnnlSalesAct),
					lapsedCustomers: Number(inputData.customerRewardsProgram.INC_InactiveCust),
					businessExpansion: inputData.customerRewardsProgram.INC_BusExpansion,
					preferredSupplier: Number(inputData.customerRewardsProgram.INC_PrefSupplier),
					grossMargin: Number(inputData.customerRewardsProgram.INC_AvgGross),
				},
				result: {
					roiPerc: customerRewardsProgram.REW_ROIPerc,
					yr1ProgCost: customerRewardsProgram.REW_Yr1ProgCost,
					yr1ProgCostPerc: customerRewardsProgram.REW_Yr1ProgCostPerc,
					yr1RevIncr: customerRewardsProgram.REW_Yr1RevIncr,
					yr1IncrGP: customerRewardsProgram.REW_Yr1IncrGP,
					incrGPPerDollar: customerRewardsProgram.REW_IncrGPPerDollar,
					roiNetIncrGP: customerRewardsProgram.REW_ROINetIncrGP,
					chartBase64: base64Chart,
				},
			})
		);
		if (file) showFile(file);
	};

	const getDocumentSales = async () => {
		const file = await requestAPIBlob(
			'POST',
			`${process.env.REACT_APP_API_URL}/api/v1/document/sales`,
			JSON.stringify({
				inputs: {
					targetAudience: 'Sales Teams',
					salesTeamMembers: Number(inputData.salesIncentiveProgram.INC_TargetSize),
					annualSalesPerMember: Number(inputData?.salesIncentiveProgram.INC_AvgAnnlSales),
					salesIncreaseTarget: Number(inputData?.salesIncentiveProgram.INC_SalesTarget),
					desiredGrowthFromNewAccounts: Number(
						inputData?.salesIncentiveProgram.INC_GrowthAccts
					),
					desiredGrowthFromCategoryExpansion: Number(
						inputData?.salesIncentiveProgram.INC_GrowthCat
					),
					grossMargin: Number(inputData?.salesIncentiveProgram.INC_AvgGross),
					salesBudgeted: Number(inputData?.salesIncentiveProgram.INC_IncPayout),
				},
				result: {
					roiPerc: salesIncentiveProgram.INC_CAL_ROIperc,
					annlCost: salesIncentiveProgram.INC_CAL_AnnlCost,
					revIncr: salesIncentiveProgram.INC_CAL_RevIncr,
					addlGross: salesIncentiveProgram.INC_CAL_AddlGross,
					increGP: salesIncentiveProgram.INC_CAL_IncrGP,
					chartBase64: base64Chart,
				},
			})
		);
		if (file) showFile(file);
	};

	const showFile = (blobFile: Blob): void => {
		const blob = new Blob([blobFile], { type: 'application/pdf' });
		const fileName = 'Lift-and-Shift-ROI-Calculator.pdf';
		const objectUrl = URL.createObjectURL(blob);
		const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

		a.href = objectUrl;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(objectUrl);
	};

	return (
		<div className='wrapper contact-us'>
			{showForm ? (
				<div>
					<Title text={'Like these results?'} />
					<p>
						Complete the form below and receive a PDF copy of these results to share
						with your team.
					</p>
					<form onSubmit={handleSubmit(onSubmitForm)}>
						<div className='d-flex justify-content-space'>
							<div className='w-49'>
								<Controller
									control={control}
									name='firstName'
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState }) => (
										<CustomInput
											maskOpt={{} as IMaskOpt}
											inputName='firstName'
											label=''
											value={value}
											type='text'
											placeholder='First Name*'
											className={`${toggleInvalidInput(fieldState.invalid)}`}
											onChange={(event) => onChange(event)}
										/>
									)}
								/>
							</div>
							<div className='w-49'>
								<Controller
									control={control}
									name='lastName'
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState }) => (
										<CustomInput
											maskOpt={{} as IMaskOpt}
											inputName='lastName'
											label=''
											type='text'
											placeholder='Last Name*'
											onChange={(event) => onChange(event)}
											className={`${toggleInvalidInput(fieldState.invalid)}`}
											value={value}
										/>
									)}
								/>
							</div>
						</div>
						<Controller
							control={control}
							name='companyName'
							rules={{ required: true }}
							render={({ field: { onChange, value }, fieldState }) => (
								<CustomInput
									maskOpt={{} as IMaskOpt}
									inputName='companyName'
									label=''
									value={value}
									type='text'
									placeholder='Company Name*'
									onChange={(event) => onChange(event)}
									className={`${toggleInvalidInput(fieldState.invalid)}`}
								/>
							)}
						/>
						<div className='d-flex justify-content-space'>
							<div className='w-49'>
								<Controller
									control={control}
									name='email'
									rules={{ required: true, pattern: EMAIL_PATTERN }}
									render={({ field: { onChange, value }, fieldState }) => (
										<CustomInput
											maskOpt={{} as IMaskOpt}
											inputName='email'
											label=''
											value={value}
											onChange={(event) => onChange(event)}
											className={`${toggleInvalidInput(fieldState.invalid)}`}
											type='text'
											placeholder='Work email*'
										/>
									)}
								/>
							</div>
							<div className='w-49'>
								<Controller
									control={control}
									name='phone'
									render={({ field: { onChange, value }, fieldState }) => (
										<CustomInput
											maskOpt={
												{
													mask: PhoneMask,
												} as IMaskOpt
											}
											inputName='phone'
											label=''
											value={value}
											type='text'
											onChange={(event) => onChange(event)}
											className={`${toggleInvalidInput(fieldState.invalid)}`}
											placeholder='Phone'
										/>
									)}
								/>
							</div>
						</div>
						<div>
							<Controller
								control={control}
								name='industryVertical'
								render={({ field: { onChange, value } }) => (
									<CustomSelect
										options={INDUSTRY_VERTICAL as ISelectOpt[]}
										value={value}
										placeholder='Choose Merchant Type'
										onChange={(value) => onChange(value)}
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name='industry'
								render={({ field: { onChange, value } }) => (
									<CustomSelect
										options={CHOOSE_INDUSTRY as ISelectOpt[]}
										value={value}
										placeholder='Choose Industry'
										onChange={(value) => onChange(value)}
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name='primaryGoal'
								render={({ field: { onChange, value } }) => (
									<CustomSelect
										options={CHOOSE_PRIMARY_GOAL as ISelectOpt[]}
										value={value}
										onChange={(value) => onChange(value)}
										placeholder='Choose your primary goal'
									/>
								)}
							/>
						</div>
						<div className='d-flex mt-2 align-items-baseline'>
							<Controller
								control={control}
								name='contactMe'
								render={({ field: { onChange, value } }) => (
									<input type='checkbox' checked={value} onChange={onChange} />
								)}
							/>
							<div>
								<span className='pl-1 text-left ml-1'>
									Please contact me to schedule a strategy session and to complete
									a more comprehensive ROI evaluation.
								</span>
							</div>
						</div>
						<button type='submit' className='btn btn__line w-100 mt-2'>
							PREPARE MY DOWNLOAD
						</button>
						<p>
							We are committed to your privacy. Lift & Shift uses the information you
							provide here to contact you about our products and services. You may
							unsubscribe from these communications at any time. For more information,
							see our{' '}
							<a
								href='https://www.lift-and-shift.com/privacy-policy'
								className='link'>
								Privacy Policy.
							</a>
						</p>
					</form>
				</div>
			) : (
				<div className='d-flex align-items-center flex-direction-column h-100'>
					<h2 style={{ fontSize: '20px' }}>Download your PDF here.</h2>
					<button
						className='btn btn__line mt-2'
						onClick={() => {
							typeProgram === 'sales' ? getDocumentSales() : getDocumentCustomer();
						}}>
						<SVGDownload className='pr-1' />
						DOWNLOAD RESULTS
					</button>
					<div className='contact-us__share mt-2'>
						<div className='d-flex flex-column align-items-center'>
							<p>To share your results with a team member, click the button below.</p>

							<button className='btn btn__outline' onClick={generateUrl}>
								<SVGDownload className='pr-1' />
								SHARE THESE RESULTS
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ContactUsForm;
