// input 1500000 - output $1,500,000.00
export const currencyFormatter = (num: number, toFixed = 0): string => {
	return '$' + num.toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const convertToShortNumber = (num: number): string => {
	const million = 1_000_000;
	const thousand = 1_000
	return num >= million ? num / million + 'M' : num >= thousand ? num / thousand + 'K' : num.toString();
};
