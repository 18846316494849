import React, {FC, useContext} from 'react';
import useMoveLogoToBottom from 'hooks/useMoveLogoToBottom';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import CustomInput from './fields/CustomInput';
import Layout from './Layout';
import {ICalcData, IMaskOpt} from 'types';
import { useCalculator } from '../hooks/useCalculator';
import { EStep } from '../content/enum';
import Title from './text/Title';
import AppContext from "../context/AppContext";

type Props = {
	title: string;
	question: string;
	description: string;
	inputName: string;
	typeNextStep: string;
	onNextStep?: () => void;
	onBackStep: () => void;
	questionTitle: string;
	rules: RegisterOptions;
	maskOpt?: IMaskOpt;
	placeholder?: string;
};
const Step: FC<Props> = ({
	title,
	question,
	description,
	inputName,
	onNextStep,
	onBackStep,
	questionTitle,
	rules,
	maskOpt,
	typeNextStep,
	placeholder,
}: Props): JSX.Element => {
	useMoveLogoToBottom();
	const { handleSubmit, control } = useFormContext();
	const { onResult } = useCalculator();
	const { changeInputData } = useContext(AppContext);
	return (
		<Layout
			btnClass={typeNextStep === EStep.RESULT ? 'btn--sm-p' : ''}
			clickNext={
				onNextStep
					? handleSubmit((data) => {
							if (typeNextStep === EStep.RESULT) {
								onResult(data as ICalcData);
								changeInputData(data as ICalcData);
							}
							onNextStep();
					})
					: undefined
			}
			clickBack={onBackStep}
			nextBtnText={typeNextStep === EStep.RESULT ? 'See My Results' : 'Next'}
			title={title}>
			<div className='step'>
				<div>
					<Title text={questionTitle} type='section' />
				</div>

				<Controller
					control={control}
					name={inputName}
					rules={rules}
					render={({ field: { onChange, value } }) => (
						<CustomInput
							inputName={inputName}
							label={question}
							onChange={onChange}
							value={value}
							maskOpt={maskOpt || ({} as IMaskOpt)}
							placeholder={placeholder}
						/>
					)}
				/>

				<div>
					<div dangerouslySetInnerHTML={{ __html: description }}></div>
				</div>
			</div>
		</Layout>
	);
};

export default Step;
