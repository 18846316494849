import { currencyFormatter } from './../../utils/currencyFormatter';
import { RULES } from './validationRules';
import { IStep } from 'types';
import {
	ADDITIONAL_TEXT_CONSTANTS,
	DEFAULT,
	FIRST_STEP_OPTIONS,
	LABEL_QUESTION,
	QUESTION_TITLE,
	TITLE,
	YES_NO_OPTIONS,
} from '.';
import { EStep } from 'content/enum';
import { NumberDollarMask, NumberPercentMask } from 'utils/maskGenerator';
import { placeholderText } from 'utils/replaceValue';

export const STEPS: IStep[] = [
	{
		inputType: 'radio',
		options: FIRST_STEP_OPTIONS,
		title: TITLE.FIRST_STEP,
		question: LABEL_QUESTION.Q1,
		description: '',
		questionTitle: '',
		inputName: 'audienceType',
		nextStep: '',
		backStep: '',
		rules: RULES.targetAudience,
		stepType: EStep.FIRST_STEP,
		titleDescription: 'One of the benefits of a well-run loyalty program is the ability to clearly measure program ROI and the impact on your business. Here we present our program ROI Calculator, to show you how the potential incremental profit it delivers will pay for the program many times over and can serve as a valuable profit center for your company. It’s easy to use the calculator yourself and share the results with your colleagues.',
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q2A,
		description: '',
		questionTitle: QUESTION_TITLE.SALES.Q2A,
		inputName: 'salesIncentiveProgram[INC_TargetSize]',
		nextStep: EStep.QUESTION_3A,
		backStep: EStep.FIRST_STEP,
		rules: RULES.integerField,
		stepType: EStep.QUESTION_2A,
		placeholder: placeholderText(DEFAULT.REW.INC_TargetSize.toString()),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q3A,
		description: '',
		questionTitle: QUESTION_TITLE.SALES.Q3A,
		inputName: 'salesIncentiveProgram[INC_AvgAnnlSales]',
		nextStep: EStep.QUESTION_4A,
		backStep: EStep.QUESTION_2A,
		rules: RULES.integerField,
		stepType: EStep.QUESTION_3A,
		maskOpt: { prefix_suffix: '$', mask: NumberDollarMask },
		placeholder: placeholderText(currencyFormatter(DEFAULT.REW.INC_AvgAnnlSales)),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q4A,
		description: '',
		questionTitle: QUESTION_TITLE.SALES.Q4A,
		inputName: 'salesIncentiveProgram[INC_SalesTarget]',
		nextStep: EStep.QUESTION_5A,
		backStep: EStep.QUESTION_3A,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_4A,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask },
		placeholder: placeholderText(DEFAULT.REW.INC_SalesTarget + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q5A,
		description: '',
		questionTitle: QUESTION_TITLE.SALES.Q5A,
		inputName: 'salesIncentiveProgram[INC_GrowthAccts]',
		nextStep: EStep.QUESTION_6A,
		backStep: EStep.QUESTION_4A,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_5A,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.REW.INC_GrowthAccts + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q6A,
		description: ADDITIONAL_TEXT_CONSTANTS.SALES.Q6A,
		questionTitle: QUESTION_TITLE.SALES.Q6A,
		inputName: 'salesIncentiveProgram[INC_GrowthCat]',
		nextStep: EStep.QUESTION_7A,
		backStep: EStep.QUESTION_5A,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_6A,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.REW.INC_GrowthCat + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q7A,
		description: ADDITIONAL_TEXT_CONSTANTS.SALES.Q7A,
		questionTitle: QUESTION_TITLE.SALES.Q7A,
		inputName: 'salesIncentiveProgram[INC_AvgGross]',
		nextStep: EStep.QUESTION_8A,
		backStep: EStep.QUESTION_6A,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_7A,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.REW.INC_AvgGross + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.SALES,
		question: LABEL_QUESTION.SALES.Q8A,
		description: ADDITIONAL_TEXT_CONSTANTS.SALES.Q8A,
		questionTitle: QUESTION_TITLE.SALES.Q8A,
		inputName: 'salesIncentiveProgram[INC_IncPayout]',
		nextStep: EStep.RESULT,
		backStep: EStep.QUESTION_7A,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_8A,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.REW.INC_IncPayout + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q2B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q2B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q2B,
		inputName: 'customerRewardsProgram[INC_ActiveCust]',
		nextStep: EStep.QUESTION_3B,
		backStep: EStep.FIRST_STEP,
		rules: RULES.integerField,
		stepType: EStep.QUESTION_2B,
		placeholder: placeholderText(DEFAULT.INC.INC_ActiveCust.toString()),
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q3B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q3B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q3B,
		inputName: 'customerRewardsProgram[INC_ProgParticipation]',
		nextStep: EStep.QUESTION_4B,
		backStep: EStep.QUESTION_2B,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_3B,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.INC.INC_ProgParticipation + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q4B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q4B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q4B,
		inputName: 'customerRewardsProgram[INC_AvgAnnlSalesAct]',
		nextStep: EStep.QUESTION_5B,
		backStep: EStep.QUESTION_3B,
		rules: RULES.integerField,
		stepType: EStep.QUESTION_4B,
		maskOpt: { prefix_suffix: '$', mask: NumberDollarMask },
		placeholder: placeholderText(currencyFormatter(DEFAULT.INC.INC_AvgAnnlSalesAct)),
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q5B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q5B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q5B,
		inputName: 'customerRewardsProgram[INC_InactiveCust]',
		nextStep: EStep.QUESTION_6B,
		backStep: EStep.QUESTION_4B,
		rules: RULES.integerField,
		stepType: EStep.QUESTION_5B,
		placeholder: placeholderText(DEFAULT.INC.INC_InactiveCust.toString()),
	},
	{
		inputType: 'radio',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q6B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q6B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q6B,
		inputName: 'customerRewardsProgram[INC_BusExpansion]',
		nextStep: EStep.QUESTION_7B,
		backStep: EStep.QUESTION_5B,
		rules: { required: true },
		stepType: EStep.QUESTION_6B,
		options: YES_NO_OPTIONS,
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q7B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q7B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q7B,
		inputName: 'customerRewardsProgram[INC_PrefSupplier]',
		nextStep: EStep.QUESTION_8B,
		backStep: EStep.QUESTION_6B,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_7B,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.INC.INC_PrefSupplier + '%'),
	},
	{
		inputType: 'number',
		title: TITLE.CUSTOMER,
		question: LABEL_QUESTION.CUSTOMER.Q8B,
		description: ADDITIONAL_TEXT_CONSTANTS.CUSTOMER.Q8B,
		questionTitle: QUESTION_TITLE.CUSTOMER.Q8B,
		inputName: 'customerRewardsProgram[INC_AvgGross]',
		nextStep: EStep.RESULT,
		backStep: EStep.QUESTION_7B,
		rules: RULES.percentageField,
		stepType: EStep.QUESTION_8B,
		maskOpt: { prefix_suffix: '%', mask: NumberPercentMask},
		placeholder: placeholderText(DEFAULT.INC.INC_AvgGross + '%'),
	},
];
