export const RULES = {
	integerField: {
		required: { value: true, message: 'This field is required. Please enter the value' },
		min: { value: 0, message: 'Minimal value of this field can be 0' },
	},
	targetAudience: { required: { value: true, message: 'Please select your target audience!' } },
	percentageField: {
		required: { value: true, message: 'This field is required. Please enter the value' },
		min: { value: 0, message: 'Minimal value of this field can be 0' },
		max: { value: 100, message: 'Maximal value of this field can be 100' },
	},
	textField: {
		required: { value: true, message: 'This field is required. Please enter the value',  min: 1 },
	}
};
