import createNumberMask from 'text-mask-addons/dist/createNumberMask';
export class MaskConfig {
	allowDecimal = true;
	decimalLimit = 2;
	prefix = '';
	suffix = '';
	public constructor(init?: Partial<MaskConfig>) {
		Object.assign(this, init);
	}
}
export const UnmaskItems: string[] = ['\\$', '%', ',', ' months'];
export const NumberDollarMaskConfig: MaskConfig = new MaskConfig({
	prefix: '$',
});
export const NumberPercentMaskConfig: MaskConfig = new MaskConfig({
	suffix: '%',
});
export const NumberInerestRateMaskConfig: MaskConfig = new MaskConfig({
	suffix: '%',
	decimalLimit: 3,
});
export const MonthMaskConfig: MaskConfig = new MaskConfig({ suffix: ' months' });
export const NumberMaskConfig: MaskConfig = new MaskConfig({
	allowDecimal: false,
	decimalLimit: 0,
	
});
export const NumberDollarMask = createNumberMask(NumberDollarMaskConfig);
export const NumberPercentMask = createNumberMask(NumberPercentMaskConfig);
export const NumberInerestRateMask = createNumberMask(NumberInerestRateMaskConfig);
export const MonthMask = createNumberMask(MonthMaskConfig);
export const NumberMask = createNumberMask(NumberMaskConfig);
export const PhoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];