export const OTHER = {
    INC_Sales_MemberRev: 0.6105,
    INC_BaseIncrTier1: 25,
    INC_Sales_Tier1: 0.2,
    INC_Sales_Tier2: 0.1,
    INC_Sales_Tier3: 0.1,
    INC_Sales_Tier4: 0.3,
    INC_Sales_InactRecap: 10,
    INC_Sales_CatIncrease: 10,
    INC_Sales_ShareWall: 25,
    INC_Cost_MemberRev: 0.005,
    INC_Cost_Tier1: 0.01,
    INC_Cost_Tier2: 0.02,
    INC_Cost_Tier3: 0.03,
    INC_Cost_Tier4: 0.04,
    INC_Cost_InactRecap1: 0.01,
    INC_Cost_InactRecap2: 50,
    INC_Cost_CatIncrease: 0.05,
    INC_Cost_ShareWall: 0.025,
}