import React, { FC, useEffect, useState } from 'react';
import { ISelectOpt } from 'types';
type Props = {
	options: ISelectOpt[];
	onChange: (value: ISelectOpt) => void;
	value: ISelectOpt;
};
interface RadioOpt extends ISelectOpt {
	selected?: false;
}
const renderOptions = (options: RadioOpt[], selectedValue: string) =>
	options.map((op) =>
		selectedValue === op.value ? { ...op, selected: true } : { ...op, selected: false }
	) as RadioOpt[];

const CustomRadio: FC<Props> = ({ options, onChange, value }: Props): JSX.Element => {
	const [isSmSize, setIsSmSize] = useState(false);
	const [radioOptions, setRadioOptions] = useState<RadioOpt[]>(
		renderOptions(options, value.value)
	);
	const selectOption = (selected: RadioOpt): void => {
		onChange(selected);
		setRadioOptions(renderOptions(radioOptions, selected.value));
	};

	useEffect(() => {
		if (window.screen.width <= 400) {
			setIsSmSize(true)
		}
	}, [])
	return (
		<div className='radio'>
			{radioOptions.map((radio: RadioOpt) => (
				<button
					key={radio.value}
					className={`radio__btn ${radio.selected ? 'radio__btn--active' : ''}`}
					type='button'
					onClick={() => selectOption(radio)}>
					{isSmSize ? radio.label.split('(')[0] : radio.label}
				</button>
			))}
		</div>
	);
};

export default CustomRadio;
