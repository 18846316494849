import React, { useContext, useState } from 'react';
import TinyBarChart from './TinyBarChart';
import ContactUsForm from './ContactUsForm';
import AppContext from 'context/AppContext';
import { TYPE_PROGRAM } from 'content/enum/EStep';
import { IBarChartData } from 'types/interfaces/IBarChartData';
import { currencyFormatter } from '../utils/currencyFormatter';
import ShareModal from './ShareModal';
import { svgToPng } from 'utils';
import useMoveLogoToBottom from 'hooks/useMoveLogoToBottom';

const ResultStep = (): JSX.Element => {
	const { resultData, typeProgram } = useContext(AppContext);
	const [show, setShow] = useState<boolean>(false);
	const [base64Chart, setBase64Chart] = useState<string>('');
	useMoveLogoToBottom();
	const togglePopup = (): void => {
		setShow((prevState) => !prevState);
	};
	const convertChartToPng = async () => {
		const copy = document.getElementById('canvas')?.cloneNode(true);
		if (copy) {
			const pngData = await svgToPng(copy as HTMLElement, 540, 500); // here you can resize img view
			pngData && setBase64Chart(pngData.replace('data:image/png;base64,', ''));
		}
	};
	const generateUrl = (): void => {
		togglePopup();
		convertChartToPng();
	};

	return (
		<div className='result__container'>
			<div className='result__wrapper'>
				<div className='result__barChart'>
					<div className='wrapper wrapper__block'>
						<h2 className='result__ROI-title'>Your Projected ROI</h2>
						<div className='result__ROI'>
							<p className='result__ROI-percent'>
								{' '}
								ROI{' '}
								{typeProgram === TYPE_PROGRAM.SALES
									? resultData.salesIncentiveProgram.INC_CAL_ROIperc
									: resultData.customerRewardsProgram.REW_ROIPerc}
								%
							</p>
							<p className='result__ROI-description'>
								For every dollar spent, <br /> you make{' '}
								<strong>
									$
									{(typeProgram === TYPE_PROGRAM.SALES
										? resultData.salesIncentiveProgram.INC_CAL_IncrGP
										: resultData.customerRewardsProgram.REW_IncrGPPerDollar
									).toFixed(2)}
								</strong>
								<br />
								additional gross profit!
							</p>
						</div>
					</div>

					<div className='wrapper wrapper__block'>
						<div className='result__table'>
							<ul>
								<li>
									<span className='table__label'>Year 1 Program Cost</span>
									<span className='table__value'>
										{currencyFormatter(
											typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_AnnlCost
												: resultData.customerRewardsProgram.REW_Yr1ProgCost
										)}
									</span>
								</li>
								<li>
									<span className='table__label'>Year 1 Revenue Increase</span>
									<span className='table__value'>
										{currencyFormatter(
											typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_RevIncr
												: resultData.customerRewardsProgram.REW_Yr1RevIncr
										)}
									</span>
								</li>
								<li>
									<span className='table__label'>
										Year 1 Incremental Gross Profit
									</span>
									<span className='table__value'>
										{currencyFormatter(
											typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_AddlGross
												: resultData.customerRewardsProgram.REW_Yr1IncrGP
										)}
									</span>
								</li>
							</ul>
						</div>
					</div>

					<div className='result__chart'>
						<h3 className='text-align'>Annual Reward Program Impact</h3>
						<TinyBarChart
							programCost={
								typeProgram === TYPE_PROGRAM.SALES
									? resultData.salesIncentiveProgram.INC_CAL_AnnlCost
									: resultData.customerRewardsProgram.REW_Yr1ProgCost
							}
							revenueIncrease={
								typeProgram === TYPE_PROGRAM.SALES
									? resultData.salesIncentiveProgram.INC_CAL_RevIncr
									: resultData.customerRewardsProgram.REW_Yr1RevIncr
							}
							grossMargin={
								typeProgram === TYPE_PROGRAM.SALES
									? resultData.salesIncentiveProgram.INC_CAL_AddlGross
									: resultData.customerRewardsProgram.REW_Yr1IncrGP
							}
							data={
								[
									{
										name: '',
										pv: `${
											+(typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_RevIncr
												: resultData.customerRewardsProgram
														.REW_Yr1RevIncr) -
											+(typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_AnnlCost
												: resultData.customerRewardsProgram.REW_Yr1ProgCost)
										}`,
										uv: `${
											+(typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_AddlGross
												: resultData.customerRewardsProgram.REW_Yr1IncrGP) -
											+(typeProgram === TYPE_PROGRAM.SALES
												? resultData.salesIncentiveProgram.INC_CAL_AnnlCost
												: resultData.customerRewardsProgram.REW_Yr1ProgCost)
										}`,
										pc: (typeProgram === TYPE_PROGRAM.SALES
											? resultData.salesIncentiveProgram.INC_CAL_AnnlCost
											: resultData.customerRewardsProgram.REW_Yr1ProgCost
										).toString(),
									},
								] as IBarChartData[]
							}
							onBinaryImage={(value: string) => setBase64Chart(value)}
						/>
					</div>
					{show && <ShareModal onClose={togglePopup} base64Chart={base64Chart} />}
				</div>
				<div className='result__contact-us'>
					<ContactUsForm
						generateUrl={generateUrl}
						base64Chart={base64Chart}
						convertChartToPng={convertChartToPng}
					/>
				</div>
			</div>
			<div className='result__disclaimer'>
				<p className='result__disclaimer-text'>
					This estimate is provided for illustrative purposes only and is not a guarantee
					of final program results. Actual results will depend on offer values, program
					promotion to the target audience and other factors tied to ongoing program
					management. Lift & Shift is a full-service reward program provider, helping our
					clients achieve optimal results with the programs we manage on their behalf.
				</p>
				<p className='result__disclaimer-text result__disclaimer-text--mt-15'>
					Please{' '}
					<a
						className='result__disclaimer-text--link'
						href='https://www.lift-and-shift.com/contact-us'>
						contact us
					</a>{' '}
					to set up a more involved program impact modelling session with you and/or your
					team.
				</p>
			</div>
		</div>
	);
};

export default ResultStep;
