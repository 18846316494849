type ReturnType = {
    requestAPI: (method: string, url: string, body: string) => void;
    requestAPIBlob: (method: string, url: string, body: string) => Promise<Blob>;
}

export const useFetchAPI = (): ReturnType => {
    const requestAPI = (method: string, url: string, body: string) => {
        fetch(`${url}`, {
            method: `${method}`,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body,
        })
            .then((r) => r.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const requestAPIBlob = async (method: string, url: string, body: string): Promise<Blob> => {
        let res;
        await fetch(`${url}`, {
            method: `${method}`,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body,
        })
            .then(r => r.blob())
            .then((data) => {
                res =  data
            })
            .catch((error) => {
                console.log(error);
            });
        if (res) {
            return res
        } else {
            return new Blob()
        }
    };

    return { requestAPI, requestAPIBlob };
};
