
export const DEFAULT = {
    REW: {
        INC_TargetSize: 250,
        INC_AvgAnnlSales: 150000,
        INC_AvgGross: 25,
        INC_IncPayout: 2,
        INC_SalesTarget: 25,
        INC_GrowthCat: 5,
        INC_GrowthAccts: 10,
    },
    INC: {
        INC_ActiveCust: 2000,
        INC_ProgParticipation: 30,
        INC_AvgAnnlSalesAct: 50000000,
        INC_InactiveCust: 200,
        INC_BusExpansion: 'yes',
        INC_PrefSupplier: 30,
        INC_AvgGross: 15,
    }
};

export const LABEL_FIRST_STEP = {
    CUSTOMERS: 'customers',
    SALES: 'sales'
}
