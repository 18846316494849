import './App.scss';
import {Calculator} from 'components';
import AppContext from 'context/AppContext';
import React, {useEffect, useState} from 'react';
import {IAppContext, ICalcData} from 'types';
import {IResultData} from "./types/interfaces/IResultData";
import {EStep} from "./content/enum";
import {decode as base64_decode} from "base-64";

const App = (): JSX.Element => {
    const [step, setStep] = useState<string>('FIRST_STEP');
    const [result, setResult] = useState<IResultData>({} as IResultData);
    const [program, setProgram] = useState<string | null >(null);
    const [stepInputData, setStepInputData] = useState<ICalcData>( {} as ICalcData)
    const contextValue: IAppContext = {
        stepType: step,
        resultData: result,
        typeProgram: program,
        inputData: stepInputData,
        changeProgram: (value: string) => setProgram(value),
        changeResult: (value: IResultData) => setResult(value),
        changeStep: (step: string) => setStep(step),
        changeInputData: (value: ICalcData) => setStepInputData(value)
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        query && getQueryVariable(query);
    }, [])

    const getQueryVariable = (query:  URLSearchParams): void => {
        const typeProgram = query.get('type')
        const resultData = query.get('resultData')
        const decoded = resultData && JSON.parse(base64_decode(resultData));
        typeProgram && setProgram(typeProgram)
        decoded && setResult(decoded as IResultData)
        typeProgram && setStep(EStep.RESULT)
    }

    return (
        <AppContext.Provider value={contextValue}>
            <Calculator/>
        </AppContext.Provider>
    );
};

export default App;
