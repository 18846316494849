import React, {FC, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import {IMaskOpt} from 'types';
import {isObjEmpty, replaceValue, splitKeys} from 'utils';

type Props = {
    inputName: string;
    label: string;
    maskOpt: IMaskOpt;
    onChange: (value: string | number) => void;
    value: number | string;
    type?: string;
    placeholder?: string;
    className?: string
};
const CustomInput: FC<Props> = ({
                                    inputName,
                                    label,
                                    value,
                                    maskOpt,
                                    type,
                                    placeholder,
                                    onChange,
                                    className
                                }: Props): JSX.Element => {

    const {
        formState: {errors},
        clearErrors,
    } = useFormContext();
    const [firstKey, secondKey] = splitKeys(inputName);

    useEffect(() => {
        return clearErrors;
    }, [clearErrors]);

    return (
        <div className='custom-input'>
            <label htmlFor={inputName} className='custom-input__label'>
                {label}
            </label>

            {isObjEmpty(maskOpt) ? (
                <input
                    type={type ? 'text' : 'number'}
                    id={inputName}
                    className={`custom-input__field ${
                        !isObjEmpty(errors) ? 'custom-input__field--error' : ''
                    } ${className}`}
                    value={value || ''}
                    placeholder={placeholder ? `${placeholder}` : ''}
                    onChange={(e) => onChange(e.target.value)}
                    autoComplete="off"
                />
            ) : (
                <MaskedInput
                    mask={maskOpt.mask}
                    className={`custom-input__field ${
                        !isObjEmpty(errors) ? 'custom-input__field--error' : ''
                    }`}
                    guide={false}
                    placeholder={placeholder ? `${placeholder}` : ''}
                    showMask
                    value={value || ''}
                    id={inputName}
                    autoComplete="off"
                    onChange={({target: {value}}) =>
                        onChange(replaceValue(value, [maskOpt.prefix_suffix || '', /,/g]))
                    }
                />
            )}

            {!isObjEmpty(errors) && (
                <p className='step__error'>{errors[firstKey][secondKey]?.message || ''}</p>
            )}
        </div>
    );
};

export default CustomInput;
