export const ADDITIONAL_TEXT_CONSTANTS = {
	SALES: {
		Q2A: '',
		Q3A: '',
		Q4A: '',
		Q5A: '',
		Q6A:
			`
				<p class="step__description">Enter the percentage expected for selling new categories to existing clients. You can enter 5% category expansion for well-developed markets and as high as 25%-35% for underdeveloped segments. </p>
				<p class="step__description">Enter zero if it does not apply. </p>
				<p class="step__description">If you're a new business... the sky’s the limit!</p>
			`,
		Q7A: '',
		Q8A: '<p class="step__description">We will automatically make allocations for bonuses on achieving stretch goals.</p>',
	},
	CUSTOMER: {
		Q2B: '<p class="step__description">Enter the number of customers who purchased in the last 12 months</p>',
		// Q3B: '<p class="step__description">Enter the percentage of customers who you think will join the program in Year 1. If you are uncertain, you can use 30% as a rough average</p>',
		Q3B: `<p class="step__description">Program participation is highly dependent on your ability/effectiveness
		at promoting your reward program with your target audience.</p>
		<p class="step__description">If you have a small number of customers (under 1,000), are in regular
		contact with many of them, and promote your program well, your
		participation in year 1 could be as high as 60%.</p>
		<p class="step__description">If you have a large number of customers but don’t communicate
		directly with a lot of them, and don’t promote your program very well,
		your year 1 participation could be between 10-15%.</p>
		<p class="step__description">Lift & Shift’s client average year 1 customer participation is
		approximately 30%, so if you are unsure what to put, use 30% for now.</p>
		`,
		Q4B: '',
		Q5B: `<p class="step__description">Enter the number of customers who purchased last year but did not purchase anything within the past 12 months.</p> 
				<p class="step__description">If you are uncertain, you can use 10% of your active customers as an estimate.</p>
				<p class="step__description">Enter 0 if you would prefer to skip this step.</p>` ,
		Q6B: '',
		Q7B: `<p class="step__description">If you are unsure of how many customers consider you their preferred
		supplier, use 30% for now.</p>`,
		Q8B: '',
	},
};
