import React from "react";

type TSVGProps = {
    width?: number,
    height?: number,
    className?: string
}

export const SVGDownload = ({ width = 25, height = 18, className = "" }: TSVGProps): JSX.Element => (
    <svg className={className} width={width} height={height} viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.18993 2.12298C8.55224 0.705774 10.3405 0 12.126 0C13.9115 0 15.6911 0.705774 17.0533 2.12298C18.6283 3.7615 19.2963 5.99388 19.0504 8.12903H19.3818C22.0101 8.12903 24.126 10.3303 24.126 13.0645C24.126 15.7988 22.0101 18 19.3818 18H5.70737C2.62488 18 0.125977 15.4003 0.125977 12.1936C0.125977 9.16601 2.34986 6.68283 5.19282 6.41431C5.3678 4.8464 6.03374 3.32579 7.18993 2.12298ZM13.0353 4.8C13.0353 4.35817 12.675 4 12.2304 4C11.7858 4 11.4255 4.35817 11.4255 4.8V13.4L8.34442 10.6125C8.03198 10.329 7.48398 10.3508 7.19998 10.6625C6.91603 10.9742 6.93208 11.5138 7.26284 11.8L11.6896 15.8C11.8046 15.9047 12.0151 15.996 12.2304 16C12.444 16.0007 12.594 15.9609 12.7712 15.8L17.198 11.8C17.5129 11.5191 17.5707 10.971 17.2608 10.6625C16.9619 10.3648 16.4288 10.329 16.1164 10.6125L13.0353 13.4V4.8Z" fill="#201C1D"/>
    </svg>
)