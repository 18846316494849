export const LABEL_QUESTION = {
	Q1: 'Who is your target audience?',
	SALES: {
		Q2A: 'How many sales team members are you looking to include in the incentive program?',
		Q3A: 'What is the average annual sales per team member?',
		Q4A: 'What is the desired sales increase target?',
		Q5A: 'What’s your desired growth from new accounts?',
		Q6A: 'What is your desired growth from category expansion?',
		Q7A: 'What is your average gross margin?',
		Q8A: 'What is the percentage of sales budgeted for the incentive payout without accounting for performance bonuses?'
	},
	CUSTOMER: {
		Q2B: 'How many active customers do you have?',
		Q3B: 'What is your expected program participation?',
		Q4B: 'What are the annual sales from your active customers?',
		Q5B: 'How many inactive customers do you have this year versus last year?',
		Q6B: 'Do you have multiple categories or product lines that more of your customers could be buying?',
		Q7B: 'What is the estimated % of active customers where you are their preferred supplier (the first they call upon)?',
		Q8B: 'What is your average gross margin?',
	},
};

export const QUESTION_TITLE = {
	SALES: {
		Q2A: 'Team Size',
		Q3A: 'Sales Performance',
		Q4A: 'Sales Performance',
		Q5A: 'Business Expansion',
		Q6A: 'Business Expansion',
		Q7A: 'Business Model',
		Q8A: 'Base Program',
	},
	CUSTOMER: {
		Q2B: 'Active Customers',
		Q3B: 'Program Participation',
		Q4B: 'Annual Sales from Active Customers',
		Q5B: 'Lapsed Customers This Year vs Last',
		Q6B: 'Business Expansion',
		Q7B: 'Preferred Supplier/Share of Wallet',
		Q8B: 'Gross Margin',
	},
}

export const TITLE = {
	FIRST_STEP: 'Calculate Your Program Loyalty ROI',
	SALES: 'Sales Teams',
	CUSTOMER: 'Customers'
}
