import React, { FC, ReactNode } from 'react';
import Title from './text/Title';

interface Props {
	children: ReactNode;
	clickNext?: () => void;
	className?: string;
	clickBack?: () => void;
	title: string;
	nextBtnText: string;
	btnClass: string;
}

const Layout: FC<Props> = ({
	children,
	className,
	clickNext,
	clickBack,
	title,
	nextBtnText = 'Next',
	btnClass = ''
}: Props): JSX.Element => {
	return (
		<div className='layout container'>
			<header>
				<Title text={title} />
			</header>

			<main className='pb-5'>
				<div className={`${className ? className : ''}`}>{children ? children : null}</div>
			</main>

			<footer className={`footer ${clickBack ? 'spaceBetween' : 'spaceCenter'}`}>
				{clickBack && (
					<button type='button' className={`btn btn__outline ${btnClass}`} onClick={clickBack}>
						Back
					</button>
				)}
				{clickNext && (
					<button type='button' onClick={clickNext} className={`btn btn__line ${btnClass}`}>
						{nextBtnText}
					</button>
				)}
			</footer>
		</div>
	);
};

export default Layout;
