export enum EStep {
    FIRST_STEP = 'FIRST_STEP',
    QUESTION_2A = 'QUESTION_2A',
    QUESTION_3A = 'QUESTION_3A',
    QUESTION_4A = 'QUESTION_4A',
    QUESTION_5A = 'QUESTION_5A',
    QUESTION_6A = 'QUESTION_6A',
    QUESTION_7A = 'QUESTION_7A',
    QUESTION_8A = 'QUESTION_8A',
    QUESTION_2B = 'QUESTION_2B',
    QUESTION_3B = 'QUESTION_3B',
    QUESTION_4B = 'QUESTION_4B',
    QUESTION_5B = 'QUESTION_5B',
    QUESTION_6B = 'QUESTION_6B',
    QUESTION_7B = 'QUESTION_7B',
    QUESTION_8B = 'QUESTION_8B',
    RESULT = 'RESULT'
}


export enum TYPE_PROGRAM {
    CUSTOMERS = 'customers',
    SALES = 'sales'
}
