import React, { FC } from 'react';
import { ISelectOpt } from 'types';

type Props = {
	options: ISelectOpt[];
	onChange: (value: string) => void;
	placeholder: string;
	value: string;
};
const CustomSelect: FC<Props> = ({ options, onChange, placeholder }: Props) => {
	return (
		<div className='custom-input'>
			<select
				onChange={(e) => onChange(e.target.value)}
				defaultValue={options[0].value}
				className='custom-input__field custom-input__field--select'
				placeholder={placeholder}>
				{options.map((opt, i) => (
					<option
						key={opt.value || i}
						disabled={opt.isDisabled}
						value={opt.isDisabled ? '' : opt.value}>
						{opt.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default CustomSelect;
