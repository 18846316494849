export const svgToPng = (svg: HTMLElement | SVGElement, width: number, height: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.fillStyle = '#ffffff00';
        }
        ctx && ctx.fillRect(0, 0, width, height);

        const xml = new XMLSerializer().serializeToString(svg);
        const dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
        const img = new Image(width, height);

        img.onload = () => {
            ctx && ctx.drawImage(img, 0, 0, width, height);
            const imageData = canvas.toDataURL('image/png', 1.0);
            resolve(imageData)
        }

        img.onerror = () => reject();
        img.src = dataUrl;
    });
};